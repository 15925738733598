import { defineMessages } from 'react-intl';

const mixed = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'Required',
  },
  notNull: {
    id: 'validation.nonNullable',
    defaultMessage: 'Required',
  },
  // In 99% of cases the type error is due to a null value in a non-nullable
  // field.
  notType: {
    id: 'validation.notType',
    defaultMessage: 'Required',
  },
});

const string = defineMessages({
  min: {
    id: 'validation.string.min',
    defaultMessage: 'Must be at least {min} characters long',
  },
  max: {
    id: 'validation.string.max',
    defaultMessage: 'Must be at most {max} characters long',
  },
  email: {
    id: 'validation.string.email',
    defaultMessage: 'Must be a valid email: example@company.com',
  },
});

const number = defineMessages({
  min: {
    id: 'validation.number.min',
    defaultMessage: 'Must be at least {min}',
  },
  max: {
    id: 'validation.number.max',
    defaultMessage: 'Must be at most {max}',
  },
  positive: {
    id: 'validation.number.positive',
    defaultMessage: 'Must be a positive number',
  },
  negative: {
    id: 'validation.number.negative',
    defaultMessage: 'Must be a negative number',
  },
  integer: {
    id: 'validation.number.integer',
    defaultMessage: 'Must be an integer',
  },
});

const array = defineMessages({
  required: {
    id: 'validation.array.required',
    defaultMessage: 'Required',
  },
  min: {
    id: 'validation.array.min',
    defaultMessage: 'Required',
  },
});

export default { number, string, mixed, array };
