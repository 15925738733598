import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

const origin = window.qsiConfig.API_ORIGIN;

const httpLink = new HttpLink({ uri: `${origin}/graphql` });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.

  const token = localStorage.getItem('qsi:auth');
  const tokenParsed = token ? JSON.parse(token) : null;

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${tokenParsed.accessToken}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache({}),
});
