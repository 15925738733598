import { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';
import checkTenantItem from 'utils/checkTenantItem';

import newRunPlanRedirect from '../../../../newRunPlanRedirect';

export const commonRunRoutes = (
  <>
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "runs" */ './components/RunSummarySection'
        ).then((p) => p.default)
      }
      query={graphql`
        query Runs_runSummarySectionQuery($runHandle: String!) {
          run(handle: $runHandle) {
            ...RunSummarySection_run
          }
        }
      `}
    />
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "runs" */ './components/RunPlotsSection'
        ).then((p) => p.default)
      }
      path="plots"
      query={graphql`
        query Runs_plotsSectionQuery(
          $runHandle: String!
          $count: Int!
          $cursor: String
        ) {
          run(handle: $runHandle) {
            ...RunPlotsSection_run
          }
        }
      `}
      prepareVariables={(v) => ({ ...getInfiniteListVariables(v), count: 5 })}
    />
    <LaunchDarklyRoute
      path="files"
      getVariation={(ldClient) =>
        ldClient.variation('access-run-files', false) && {
          getComponent: () =>
            import(
              /* webpackChunkName: "runs" */ './components/RunFilesSection'
            ).then((p) => p.default),
          query: graphql`
            query Runs_filesSectionQuery($runHandle: String!) {
              run(handle: $runHandle) {
                ...RunFilesSection_run
              }
            }
          `,
        }
      }
    />
  </>
);

export default (
  <Route path="runs">
    <Route
      path="-/new"
      prerender={newRunPlanRedirect}
      getQuery={newRunPlanRedirect.getQuery}
    />
    <Route path="plan">
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "runs" */ './components/NewRunPlanPage'
          ).then((p) => p.default)
        }
        prepareVariables={(params, { location }) => ({
          ...params,
          runHandle: location.query.run || '',
          hasRun: !!location.query.run,
          libraryHandle: location.query.library || '',
          hasLibrary: !!location.query.library,
        })}
        patternRun={null}
        fromLibrary={null}
        query={graphql`
          query Runs_newRunPlanQuery(
            $tenantSlug: String!
            $projectHandle: String!
            $runHandle: String!
            $hasRun: Boolean!
            $libraryHandle: String!
            $hasLibrary: Boolean!
          ) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...NewRunPlanPage_tenant
            }
            project: project(handle: $projectHandle) {
              ...NewRunPlanPage_project
            }
            patternRun: run(handle: $runHandle) @include(if: $hasRun) {
              ...NewRunPlanPage_patternRun
            }
            fromLibrary: library(handle: $libraryHandle)
              @include(if: $hasLibrary) {
              ...NewRunPlanPage_fromLibrary
            }
          }
        `}
      />
      <Route
        path="-/new2"
        getComponent={() =>
          import(/* webpackChunkName: "runs" */ './page/NewRunPlanPage').then(
            (p) => p.default,
          )
        }
        prepareVariables={(params, { location }) => ({
          ...params,
          runHandle: location.query.run || '',
          hasRun: !!location.query.run,
          libraryHandle: location.query.library || '',
          hasLibrary: !!location.query.library,
        })}
      />
    </Route>
    <Route
      path="-/import"
      getComponent={() =>
        import(
          /* webpackChunkName: "runs" */ './components/ImportRunPage'
        ).then((p) => p.default)
      }
      prepareVariables={(params) => ({
        ...params,
      })}
      query={graphql`
        query Runs_importRunQuery(
          $tenantSlug: String!
          $projectHandle: String! # $runHandle: String! # $hasRun: Boolean! # $libraryHandle: String! # $hasLibrary: Boolean!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...ImportRunPage_tenant
          }
          project: project(handle: $projectHandle) {
            ...ImportRunPage_project
          }
        }
      `}
    />
    <Route
      path="-/import2"
      getComponent={() =>
        import(/* webpackChunkName: "runs" */ './page/ImportRunPlanPage').then(
          (p) => p.default,
        )
      }
      prepareVariables={(params) => ({
        ...params,
      })}
    />
    <Route
      query={graphql`
        query Runs_runQuery($tenantSlug: String!, $runHandle: String!) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: run(handle: $runHandle) {
            tenant {
              id
            }
          }
        }
      `}
      path=":runHandle"
      prerender={checkTenantItem}
    >
      <Route
        getComponent={() =>
          import(/* webpackChunkName: "runs" */ './components/RunPage').then(
            (p) => p.default,
          )
        }
        query={graphql`
          query Runs_runPageQuery($runHandle: String!) {
            run(handle: $runHandle) {
              ...RunPage_run
            }
          }
        `}
      >
        {commonRunRoutes}
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "runs" */ './components/RunLibraryLoadingSection'
            ).then((p) => p.default)
          }
          path="library-loading"
          query={graphql`
            query Runs_libraryLoadingSectionQuery($runHandle: String!) {
              run(handle: $runHandle) {
                ...RunLibraryLoadingSection_run
              }
            }
          `}
        />
        <Route
          path="analyses"
          getComponent={() =>
            import(
              /* webpackChunkName: "runs" */ './components/RunAnalysesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Runs_analysesSectionQuery(
              $runHandle: String!
              $count: Int!
              $cursor: String
              $sort: [AnalysisSorting!]
            ) {
              run(handle: $runHandle) {
                ...RunAnalysesSection_run
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <LaunchDarklyRoute
          path="insights"
          getVariation={(ldClient) =>
            ldClient.variation('access-run-insights', false) && {
              getComponent: () =>
                import(
                  /* webpackChunkName: "runs" */ './components/RunInsightsSection'
                ).then((p) => p.default),
              query: graphql`
                query Runs_insightsSectionQuery(
                  $runHandle: String!
                  $count: Int!
                  $cursor: String
                  $sort: [AnalysisSorting!]
                ) {
                  run(handle: $runHandle) {
                    ...RunInsightsSection_run
                  }
                }
              `,
            }
          }
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "runs" */ './components/RunSplitsSection'
            ).then((p) => p.default)
          }
          path="splits"
          query={graphql`
            query Runs_splitsSectionQuery($runHandle: String!) {
              run(handle: $runHandle) {
                ...RunSplitsSection_run
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "runs" */ './components/RunNotesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Runs_notesSectionQuery(
              $runHandle: String!
              $count: Int!
              $cursor: String
            ) {
              run(handle: $runHandle) {
                ...RunNotesSection_run
              }
            }
          `}
          path="notes"
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "runs" */ './components/EditRunPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Runs_editRunPageQuery($runHandle: String!) {
            run(handle: $runHandle) {
              ...EditRunPage_run
            }
          }
        `}
      />
      <Route
        path="edit2"
        getComponent={() =>
          import(/* webpackChunkName: "runs" */ './page/EditRunPlanPage').then(
            (p) => p.default,
          )
        }
        prepareVariables={(params, { location }) => ({
          ...params,
          runHandle: location.query.run || '',
        })}
      />
    </Route>
  </Route>
);
